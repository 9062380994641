<template>
  <h2 class="text-center">Sliders</h2>
<ul class="text-center">
  <li class="my-8"><h3 class="pb-8">NR. 1</h3>
    <SliderNr1/>
  </li>
</ul>

</template>

<script>

import SliderNr1 from "../../components/pagesections/Sliders/SliderNr1";
export default {
  name: "Sliders",

  components: {SliderNr1}
}
</script>

<style scoped>

</style>